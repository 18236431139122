import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import trackInvestorsObj from "~/utils/templates/trackInvestors.json";
var guruUtils = function guruUtils() {
  var trackInvestors = computed(function () {
    return trackInvestorsObj;
  });
  var permIdObj = computed(function () {
    var investorObj = trackInvestors.value || {};
    return Object.keys(investorObj).reduce(function (acc, catKey) {
      var catList = investorObj[catKey];
      var top10 = catList.slice(0, 10);
      top10.forEach(function (invObj) {
        acc[invObj.permId] = true;
      });
      return acc;
    }, {});
  });
  return {
    trackInvestors: trackInvestors,
    permIdObj: permIdObj
  };
};
export default guruUtils;